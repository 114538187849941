<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {}
  },
  mounted() {
    window.addEventListener('resize', this.resizeListener)
    this.resizeListener()
    document.title = '深圳市瑞麟数字科技控股有限公司'
  },
  methods: {
    resizeListener() {
      // 定义设计图的尺寸 1920
      const designSize = 1920
      // 获取 html 元素
      const html = document.documentElement
      // 定义窗口的宽度
      const clientW = html.clientWidth
      // html 的fontsize 大小
      const htmlRem = (clientW * 100) / designSize
      html.style.fontSize = `${htmlRem}px`
    },
  },
}
</script>

<style scoped>
.el-container {
  display: flex;
  flex-direction: column;
}
.el-main {
  padding: 0;
  box-sizing: border-box;
  overflow: inherit;
}</style
>>
